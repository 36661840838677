import { MediaItem } from './item';
import { MediaItemImageDisplay } from './item_image';

/**
 * Class MediaItemDocument
 *
 * Represents a MediaItem of type document.
 */
export const MediaItemDocument = new Class({
   Extends: MediaItem,

   /**
    * Returns a list of menu functions that should be allowed
    * for this media item in its current state.
    */
   _getMenuOptions: function () {
      return {};
   },

   /**
    * Creates an appropriate representation for the given MediaTarget and
    * passes it as the only argument to the provided callback when it's
    * ready.
    */
   createRepresentationFor: function (mediaTarget, callback) {
      let size = mediaTarget.options.displaySize;
      let self = this;
      self.whenReady(function () {
         let thumb = self.createDisplay(size, false);
         thumb.whenReady(function () {
            callback(thumb);
         });
      });
   },

   /**
    * Instantiates a MediaItemImageDisplay for this document
    * whose element will automatically update to reflect any state
    * changes in this item.
    */
   createDisplay: function (size, showFilename) {
      let thumb;
      size = this.getLargestImageSize(size);
      thumb = new MediaItemImageDisplay(this, {
         size: size,
         useBackground: true,
         showFilename: showFilename,
         classOverride: 'mediaDocument',
      });
      return thumb;
   },

   /**
    * Returns the documentid of the document that this mediaItem represents.
    */
   getID: function () {
      return this.data.documentid();
   },

   getFilename: function () {
      return this.data.filename();
   },

   getTitle: function () {
      return this.data.title();
   },

   getType: function () {
      return this.data.type();
   },

   getFilter: function () {
      return 'onlyDocuments';
   },

   /**
    * Returns the url for this image for the specified size.
    *
    * size: 'thumbnail', 'medium', ...
    */
   getSrc: function (size) {
      return this.data[size]();
   },

   getViewUrl: function () {
      return this.data.view_url();
   },

   getIconClassname: function () {
      // js implementation of DisplayDocument's getIconClassname
      let type = this.data.document_type();
      let iconClassname = 'fa-file-o';

      if (['pdf', 'word', 'excel', 'powerpoint'].includes(type)) {
         iconClassname = 'fa-file-' + type + '-o';
      }

      return (
         'pdf-document attachment-icon fa ' +
         iconClassname +
         ' fa-2x officeDocumentIcon document-icon-' +
         type
      );
   },

   toWikiText: function () {
      return '[document|' + this.getID() + ']';
   },

   /**
    * Returns the media type and underlying imageid that this mediaItem
    * represents.
    */
   getGlobalID: function () {
      return 'document:' + this.getID();
   },
});

MediaItem.registerType('Document', MediaItemDocument);
