import { createChakraComponent } from 'Shared/chakra-initialize';
import createOpenPromise from './createOpenPromise';
import MediaLibraryComponent, { MediaInterface } from './media_library_component';

let [mediaInterfacePromise, resolveMediaInterface] = createOpenPromise<MediaInterface>();

const container = createChakraComponent(MediaLibraryComponent, {
   resolveMediaInterface: resolveMediaInterface,
});
document.body.append(container);

export const mediaPrompt = async (filter: string | null) => {
   const mediaInterface = await mediaInterfacePromise;
   return mediaInterface.open({ filter });
};
