import * as React from 'react';
import { MediaLibrary, MediaItem } from '@dozuki/web-js/components';
import { _js } from '@ifixit/localize';
import createOpenPromise from './createOpenPromise';

export type MediaInterface = {
   open: (options: { filter: string | null }) => Promise<MediaItem | null>;
};

type MediaLibraryComponentProps = {
   readonly resolveMediaInterface: (value: MediaInterface) => any;
};

type Prompt = {
   filter: string | null;
   promise: Promise<MediaItem | null>;
   resolve: (mediaItem: MediaItem | null) => any;
};

export default function MediaLibraryComponent({
   resolveMediaInterface,
}: MediaLibraryComponentProps) {
   const [prompt, setPrompt] = React.useState<Prompt | null>(null);

   const open = (options: { filter: string | null }) => {
      let [promise, resolve] = createOpenPromise<MediaItem | null>();
      setPrompt({ filter: options.filter, promise, resolve });
      return promise;
   };

   const handleClose = () => {
      if (!prompt) return;
      prompt.resolve(null);
      setPrompt(null);
   };

   const handleSelect = (mediaItem: MediaItem) => {
      if (!prompt) return;
      prompt.resolve(mediaItem);
      setPrompt(null);
   };

   const [mediaInterface] = React.useState({ open });

   Object.assign(mediaInterface, { open });

   React.useEffect(() => {
      resolveMediaInterface(mediaInterface);
   }, []);

   if (!prompt) {
      return null;
   }

   return (
      <MediaLibrary
         // Can't type this correctly with the current WSJS typing,
         //    will resolve in a follow-up issue.
         filter={prompt ? (prompt.filter as any) : null}
         _js={_js}
         isOpen={true}
         onClose={handleClose}
         onSelect={handleSelect}
      />
   );
}
